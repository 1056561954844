html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
.simplert {
  font-size: 14px;
  line-height: 1.15;
}
.simplert {
  position: fixed;
  z-index: 1500;
  top: 0;
  left: 0;
  display: none;
  overflow: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
}
.simplert--shown {
  display: flex;
  align-items: center;
}
.simplert--shown .simplert__content {
  animation-name: zoomIn;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}
.simplert__header {
  padding: 2em 0;
}
.simplert__title {
  font-size: 30px;
  display: block;
}
.simplert__content {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-border-radius: 0.3em;
  -moz-border-radius: 0.3em;
  border-radius: 0.3em;
}
@media only screen and (min-width: 768px) {
  .simplert__content {
    width: 30%;
  }
}
.simplert__body {
  padding-bottom: 1em;
}
.simplert__icon {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 10px auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.simplert__icon--info {
  border: 4px solid #c9dae1;
}
.simplert__icon--success {
  border: 4px solid #a5dc86;
}
.simplert__icon--warning {
  border: 4px solid #f8bb86;
}
.simplert__icon--error {
  border: 4px solid #f27474;
}
.simplert__icon img {
  width: 80px;
  height: 80px;
}
.simplert__line {
  position: absolute;
  z-index: 2;
  display: block;
  height: 5px;
  border-radius: 2px;
}
.simplert__line--info {
  position: absolute;
  top: 19px;
  left: 50%;
  width: 7px;
  height: 7px;
  margin-left: -3px;
  border-radius: 50%;
  background-color: #c9dae1;
}
.simplert__line--info-2 {
  position: absolute;
  bottom: 15px;
  left: 50%;
  width: 5px;
  height: 29px;
  margin-left: -2px;
  border-radius: 2px;
  background-color: #c9dae1;
}
.simplert__line--success {
  top: 44px;
  left: 6px;
  width: 25px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #a5dc86;
}
.simplert__line--success-2 {
  top: 38px;
  right: 6px;
  width: 47px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #a5dc86;
}
.simplert__line--warning {
  position: absolute;
  top: 10px;
  left: 50%;
  width: 5px;
  height: 35px;
  margin-left: -2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: #f8bb86;
}
.simplert__line--warning-2 {
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 7px;
  height: 7px;
  margin-left: -3px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #f8bb86;
}
.simplert__line--error {
  top: 35px;
  left: 13px;
  width: 47px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #f27474;
}
.simplert__line--error-2 {
  top: 35px;
  right: 13px;
  width: 47px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #f27474;
}
.simplert__footer {
  padding: 1em 0;
}
.simplert__close, .simplert__confirm {
  display: inline-block;
  margin: 0 0.5em;
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
  background-color: #068ac9;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0.3em;
  -moz-border-radius: 0.3em;
  border-radius: 0.3em;
}
@keyframes zoomIn {
  from {
    transform: scale3d(0.3, 0.3, 0.3);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
